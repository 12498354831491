import React from "react"
import styled from "styled-components"

import { useTranslation } from "react-i18next"
import mobileBackground from "../images/bg-mobile.png"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Content>
        <XXXXXX>
          <Topic>{t("404.topic")}</Topic>
          <Topic2>{t("404.topic2")}</Topic2>
        </XXXXXX>
      </Content>
    </>
  )
}
const Topic = styled.div`
  font-size: 60px;
  font-weight: bold;
  @media only screen and (max-width: 768px) {
    & {
      font-size: 40px;
    }
  }
`

const Topic2 = styled.div`
  font-size: 18px;
  white-space: pre-line;
  @media only screen and (max-width: 600px) {
    & {
      font-size: 14px;
    }
  }
`

const Content = styled.div`
  margin: 0px auto;
  padding: 50px 30px 30px;
  max-width: 1360px;
  @media only screen and (max-width: 768px) {
    & {
      // padding: 50px 0px 16px;
      padding: 0px;
    }
  }
`

const XXXXXX = styled.div`
  @media only screen and (max-width: 768px) {
    & {
      background-image: url(${mobileBackground});
      background-size: contain;
      background-position-x: center;
      background-position-y: bottom;
      background-repeat: no-repeat;
      padding: 20px 16px 0px;
      height: calc(100vh - 110px);
    }
  }

  @media only screen and (max-width: 600px) {
    & {
      height: 450px;
    }
  }
`

export default NotFoundPage
